import { db } from "./firebase";

import {
  upvoteEnlights,
  commentEnlights,
  IBuiltThisEnlights
} from "../constants/constants";

// User Registration API

export const createUser = (
  uid,
  username,
  email_address,
  created_at,
  photoURL
) =>
  db
    .ref("users")
    .child(uid)
    .once("value", function(snapshot) {
      var exists = snapshot.val() !== null;
      userExistsCallback(
        exists,
        uid,
        username,
        email_address,
        created_at,
        photoURL
      );
    });
function userExistsCallback(
  exists,
  uid,
  username,
  email_address,
  created_at,
  photoURL
) {
  if (exists) {
    db.ref(`users/${uid}`).update({
      photoURL
    });
    console.log("User already registered and now is logged in.");
  } else {
    // update user count
    db.ref("user-count")
      .once("value")
      .then(snapshot => {
        let count = snapshot.val().count + 1;
        db.ref("user-count").set({
          count
        });

        // id followed by user number
        let id = username.replace(/ .*/, "");
        id = id.toLowerCase() + count;

        // add user to users ref!!
        db.ref(`users/${uid}`).set({
          username,
          email_address,
          created_at,
          id,
          photoURL
        });
      });
  }
}

// Submissions API

export const pushSubmission = (
  author,
  author_uid,
  author_username,
  email,
  title,
  description,
  language,
  difficulty,
  category,
  img,
  demoURL,
  sourceURL,
  content,
  date,
  id,
  published,
  submitted
) =>
  db.ref("projectSubmissions/").push({
    author,
    author_uid,
    author_username,
    email,
    title,
    description,
    language,
    difficulty,
    category,
    img,
    demoURL,
    sourceURL,
    content,
    date,
    id,
    published,
    submitted
  });

export const updateSubmission = (
  author,
  author_uid,
  author_username,
  email,
  title,
  description,
  language,
  difficulty,
  category,
  img,
  demoURL,
  sourceURL,
  content,
  date,
  id,
  published,
  key,
  submitted
) =>
  db.ref(`projectSubmissions/${key}`).update({
    author,
    author_uid,
    author_username,
    email,
    title,
    description,
    language,
    difficulty,
    category,
    img,
    demoURL,
    sourceURL,
    content,
    date,
    id,
    published,
    submitted
  });

// Presence API

export const removePresence = id => {
  const userRef = db.ref(`presence/${id}`);
  userRef.remove();
};

export const usersOnline = () => db.ref("presence").once("value");

export const checkUserPresence = uid => db.ref("presence").child(uid);

export const setPresence = uid => {
  const userRef = db.ref(`presence/${uid}`);

  db.ref(".info/connected").on("value", function(snap) {
    if (snap.val()) {
      db.ref("users")
        .child(uid)
        .on("value", function(snapshot) {
          let data = snapshot.val();

          if (typeof window !== "undefined" && window.location.pathname) {
            let url = window.location.pathname;
            let timestamp = Date.now();
            userRef.update({
              status: "online",
              current_page: url,
              timestamp: timestamp
            });
          }
        });
      userRef.onDisconnect().remove();
    }
  });
};

// User Data API

export const fetchUserData = id =>
  db
    .ref("users")
    .orderByChild("id")
    .equalTo(id);

export const fetchUserDataByUid = uid => db.ref("users").child(uid);

export const updateUsername = (uid, new_id) => {
  db.ref("users")
    .orderByChild("id")
    .equalTo(new_id)
    .once("value", function(snapshot) {
      var exists = snapshot.val() !== null;
      userCallback(exists);
    });

  function userCallback(exists) {
    if (exists) {
      alert("Username Taken");
    } else {
      db.ref("users")
        .child(uid)
        .update({ id: new_id });
    }
  }
};

export const updateName = (uid, new_name) => {
  db.ref("users")
    .child(uid)
    .update({ username: new_name });
};

export const updateSocial = (
  uid,
  bio,
  twitter,
  public_email,
  website,
  github
) => {
  db.ref("users")
    .child(uid)
    .update({
      bio: bio,
      twitter: twitter,
      public_email: public_email,
      website: website,
      github: github
    });
};

export const retrieveUpvotes = post_id =>
  db
    .ref("upvotes")
    .orderByChild("post_id")
    .equalTo(post_id)
    .once("value");

export const retrievePostsUserUpvoted = uid =>
  db
    .ref("upvotes")
    .orderByChild("uid")
    .equalTo(uid)
    .once("value");

export const retrievePostsUserCommented = uid =>
  db.ref("comments").once("value");

// Project API (Upvotes, Comments, etc.)

export const addUpvote = (uid, post_id, post_title, post_language) => {
  let time = new Date().toString();
  db.ref("upvotes")
    .orderByChild("uid")
    .once("value", function(snapshot) {
      if (snapshot.exists()) {
        let firstUpvote = true;
        snapshot.forEach(function(childSnap) {
          let childData = childSnap.val();
          if (childData.uid === uid && childData.post_id === post_id) {
            firstUpvote = false;
            alert("You may only upvote once.");
          }
        });
        if (firstUpvote) {
          db.ref("upvotes").push({
            uid: uid,
            post_id: post_id,
            post_title: post_title,
            post_language: post_language,
            time: time
          });
          addEnlights(uid, upvoteEnlights);
        }
      } else {
        db.ref("upvotes").push({
          uid: uid,
          post_id: post_id,
          post_title: post_title,
          post_language: post_language,
          time: time
        });
        addEnlights(uid, upvoteEnlights);
      }
    });
};

export const pushComment = (
  uid,
  comment,
  post_title,
  post_language,
  post_id
) => {
  let timestamp = Date.now();
  addEnlights(uid, commentEnlights);
  db.ref(`comments/${post_id}`).push({
    uid,
    comment,
    timestamp,
    post_title,
    post_language,
    post_id
  });
};

export const onCommentAdded = (post, callback) => {
  db.ref(`comments/${post}`)
    .orderByChild("timestamp")
    .limitToLast(100)
    .on("child_added", callback);
};

export const updatePostRef = (
  author_uid,
  post_id,
  post_title,
  post_language,
  publish_date,
  post_description,
  post_img,
  post_difficulty,
  post_demourl,
  post_source,
  content
) => {
  alert(post_id);
  db.ref(`projects/${post_id}`).update({
    author_uid,
    post_id,
    post_title,
    post_language,
    publish_date,
    post_description,
    post_img,
    post_difficulty,
    post_demourl,
    post_source,
    content
  });
};

export const updateProjectRef = (
  title,
  date,
  description,
  author,
  author_username,
  author_uid,
  difficulty,
  category,
  img,
  demoURL,
  sourceURL,
  language,
  id,
  template,
  mdxBody,
  rawBody,
  toc,
  timeToRead
) => {
  db.ref(`webProjects/${id}`).set({
    title,
    date,
    description,
    author,
    author_username,
    author_uid,
    difficulty,
    category,
    img,
    demoURL,
    sourceURL,
    language,
    id,
    template,
    mdxBody,
    rawBody,
    toc,
    timeToRead
  });
};

// Admin Dashboard API

export const fetchSubmittedTutorialById = projectId =>
  db
    .ref("projectSubmissions")
    .orderByChild("id")
    .equalTo(projectId);

export const fetchTutorialById = projectId =>
  db.ref(`webProjects/${projectId}`);

export const pushSubmittedTutorialToWeb = (
  author,
  author_uid,
  author_username,
  category,
  rawBody,
  date,
  demoURL,
  description,
  difficulty,
  id,
  img,
  language,
  projectId,
  published,
  sourceURL,
  title
) => {
  db.ref(`webProjects/${id}`).update({
    author: author,
    author_uid: author_uid,
    author_username: author_username,
    category: category,
    date: date,
    demoURL: demoURL,
    description: description,
    difficulty: difficulty,
    id: id,
    img: img,
    language: language,
    projectId,
    projectId,
    published: published,
    rawBody: rawBody,
    sourceURL: sourceURL,
    title: title
  });
  addUserTag(author_uid, "contributor");
};

export const addOneToTutorialCount = () =>
  db.ref("tutorial-count").transaction(function(tutorialCount) {
    return tutorialCount + 1;
  });

export const deleteSubmittedTutorial = key => {
  db.ref(`projectSubmissions/${key}`).remove();
};

export const updateSubmittedTutorial = (
  author,
  author_uid,
  author_username,
  category,
  content,
  date,
  demoURL,
  description,
  difficulty,
  email,
  id,
  img,
  language,
  published,
  sourceURL,
  title,
  key
) => {
  db.ref(`projectSubmissions/${key}`).update({
    author: author,
    author_uid: author_uid,
    author_username: author_username,
    category: category,
    content: content,
    date: date,
    demoURL: demoURL,
    description: description,
    difficulty: difficulty,
    email: email,
    id: id,
    img: img,
    language: language,
    published: published,
    sourceURL: sourceURL,
    title: title
  });
};

export const loadSavedSubmission = author_uid =>
  db
    .ref("projectSubmissions")
    .orderByChild("author_uid")
    .equalTo(author_uid)
    .once("value");

export const retrievePostsUserWrote = uid =>
  db
    .ref("webProjects")
    .orderByChild("author_uid")
    .equalTo(uid)
    .once("value");

// Enlights API
export const updateEnlights = (uid, enlights) => {
  db.ref(`enlights/${uid}`).set(enlights);
};

export const addEnlights = (uid, enlightsToAdd) => {
  getEnlights(uid).then(snapshot => {
    let enlights;
    if (snapshot.val()) {
      enlights = snapshot.val().enlights;
    } else {
      enlights = 0;
    }
    let newEnlights = enlights + enlightsToAdd;
    db.ref(`enlights/${uid}`).update({
      enlights: newEnlights
    });
  });
};

export const getEnlights = id => db.ref(`enlights/${id}`).once("value");

export const getLeaderboard = () => db.ref("enlights").once("value");

// Metrics API

export const getUserCount = () => db.ref("user-count").once("value");

export const getUserActivity = () =>
  db
    .ref(`user-activity`)
    .limitToLast(7)
    .once("value", snapshot => {
      var days = snapshot.val(); // this is our object of objects
      days = Object.entries(days); // this is our array of arrays where each array is made up of the date [0], and an object at index [1]
      let data = [];
      days.forEach((day, index) => {
        data.push(Object.values(day[1])[0]);
      });
      return data;
    });

export const getIBuiltThis = uid =>
  db
    .ref(`IBuiltThisSubmissions`)
    .orderByChild("uid")
    .equalTo(uid);

export const getCustomIBuiltThis = uid =>
  db
    .ref("CustomIBuiltThisSubmissions")
    .orderByChild("uid")
    .equalTo(uid);

export const getCohortDate = cohort => db.ref(`cohort/${cohort}/date`);

export const getAllIBuiltThis = () =>
  db.ref(`IBuiltThisSubmissions`).once("value");

export const getRecentProjectSubmissions = callback =>
  db
    .ref(`IBuiltThisSubmissions`)
    .endAt()
    .limitToLast(200)
    .on("child_added", callback);

export const getTotalEnlightsRef = () => db.ref("enlights").once("value");

export const getWebProjects = () => db.ref("webProjects").once("value");

export const getTutorialSubmissions = () =>
  db.ref("projectSubmissions").once("value");

export const getAllMarketingCopy = () => db.ref("marketingCopy").once("value");

export const getMarketingCopy = (group, slug) =>
  db.ref(`marketingCopy/${group}/${slug}`).once("value");

export const IBuiltThisSubmission = (
  github,
  demo,
  uid,
  title,
  id,
  description,
  img,
  numericalId,
  isCohort
) => {
  let date = new Date();
  db.ref(`users/${uid}`).once("value", snapshot => {
    let userData = snapshot.val();
    if (isCohort) {
      db.ref(`cohort/activeCohort`).once("value", snapshot => {
        let activeCohort = snapshot.val();
        let tags = userData.tags;
        if (tags.includes("team") || tags.includes("mentor")) {
          let tag = "cohort " + activeCohort;
          if (!tags.includes(tag)) {
            tags.push(tag);
          }
        }
        db.ref("IBuiltThisSubmissions").push({
          github: github,
          demo: demo,
          uid: uid,
          date: date.toString(),
          title: title,
          postId: id,
          description: description,
          img: img,
          numericalId: numericalId,
          tags: tags
        });

        // let content =
        //   "<main class='built-this'> completed " +
        //   "**" +
        //   title +
        //   "** <br/><br/>" +
        //   "<i>" +
        //   description +
        //   "</i>" +
        //   "<br/><br/>" +
        //   "\n\n<a target='_blank' class='source' href='" +
        //   github +
        //   "'> source </a>" +
        //   "<a target='_blank' class='demo' href='" +
        //   demo +
        //   "'> demo </a></main><br/><br>";
        // pushMakerLogSubmission(uid, content, img); // push to maker log
      });
    } else {
      db.ref("IBuiltThisSubmissions").push({
        github: github,
        demo: demo,
        uid: uid,
        date: date.toString(),
        title: title,
        postId: id,
        description: description,
        img: img,
        numericalId: numericalId
      });
    }
  });
  // addEnlights(uid, IBuiltThisEnlights); uncomment when ready!
};

// called when !tutorialStarted on Read More click
export const pushToUserActivity = (uid, projectSlug, projectId) => {
  let today = new Date();
  db.ref(`user-activity/${uid}`).push({
    projectSlug: projectSlug,
    projectId: projectId,
    timeStarted: today.getTime(),
    timesVisited: 1,
    tutorialCompleted: false,
    timeFinished: null,
    timeLastSeen: today.getTime()
  });
  updateMetrics("start", null);
  updateMetrics("user", uid);
};

// called only from db functions: pushToUserActivity and updateUserActivity
export const updateMetrics = (command, uid) => {
  let today = new Date();
  let day = `${("0" + (today.getMonth() + 1)).slice(-2)}-${(
    "0" + today.getDate()
  ).slice(-2)}-${today.getFullYear()}`;

  let metricsRef = db.ref(`metrics/${day}`);

  metricsRef.once("value", snapshot => {
    if (!snapshot.exists()) {
      metricsRef
        .update({
          tutorialStarts: 0,
          tutorialCompletions: 0
        })
        .then(updateMetricsRef(metricsRef, command, uid));
    } else {
      updateMetricsRef(metricsRef, command, uid);
    }
  });
};

// called only from updateMetrics
export const updateMetricsRef = (metricsRef, command, uid) => {
  if (command == "start") {
    metricsRef.child("tutorialStarts").transaction(function(tutorialStarts) {
      return tutorialStarts + 1;
    });
  } else if (command == "completion") {
    metricsRef
      .child("tutorialCompletions")
      .transaction(function(tutorialCompletions) {
        return tutorialCompletions + 1;
      });
  } else if (command == "user") {
    let uidRef = metricsRef.child(`uids/${uid}`);
    uidRef.once("value", snapshot => {
      if (!snapshot.val()) {
        uidRef.set(1);
      } else {
        uidRef.transaction(function(timesReturned) {
          return timesReturned + 1;
        });
      }
    });
  } else {
    if (process.env.NODE_ENV != "production") {
      console.log("error: incorrect command passed");
    }
  }
};

export const updateUserActivity = (uid, projectId, tutorialCompleted) => {
  let today = new Date();
  const tutorialRef = db
    .ref(`user-activity/${uid}`)
    .orderByChild("projectId")
    .equalTo(projectId);
  tutorialRef.once("value", function(snapshot) {
    let data = snapshot.val();
    data = Object.entries(data);
    let pushKey = data[0][0];
    let timesVisited = tutorialCompleted
      ? data[0][1]["timesVisited"]
      : data[0][1]["timesVisited"] + 1;
    let timeFinished = tutorialCompleted ? today.getTime() : null;
    let timenow = today.getTime();
    db.ref(`user-activity/${uid}/${pushKey}`).update({
      timesVisited: timesVisited,
      tutorialCompleted: tutorialCompleted,
      timeFinished: timeFinished,
      timeLastSeen: timenow
    });
  });

  updateMetrics("user", uid);
  if (tutorialCompleted) {
    updateMetrics("completion", null);
  }
};

export const userActivityTutorialRef = (uid, projectId) =>
  db
    .ref(`user-activity/${uid}`)
    .orderByChild("projectId")
    .equalTo(projectId);

export const getPastTwoWeeksMetrics = () =>
  db
    .ref("metrics")
    .limitToLast(14)
    .once("value");

export const populateMarketingCopy = (
  group,
  slug,
  heading,
  label,
  description,
  title,
  createPage
) => {
  db.ref(`marketingCopy/${group}/${slug}`).update({
    heading: `${heading}`,
    label: `${label}`,
    description: `${description}`,
    title: `${title}`,
    createPage: createPage
  });
};

export const createPageMarketing = (group, slug) => {
  db.ref(`marketingCopy/${group}/${slug}`).update({
    createPage: true
  });
};

// Onboarding API

export const pushOnboarding = (uid, type, answer) => {
  if (type == "interest") {
    db.ref(`onboarding/${uid}`).update({
      interest: answer,
      datetime: Date.now()
    });
  } else if (type == "motivation") {
    db.ref(`onboarding/${uid}`).update({
      motivation: answer,
      datetime: Date.now()
    });
  } else if ((type = "experience")) {
    db.ref(`onboarding/${uid}`).update({
      experience: answer,
      datetime: Date.now()
    });
  }
  updateOnboardingMetrics(type, answer);
};

export const getOnboardingRawData = () => db.ref(`onboarding`).once("value");

export const getUserOnboardingData = uid =>
  db.ref(`onboarding/${uid}`).once("value");

export const addOnboardingCount = () => {
  db.ref("onboardingMetrics/count").transaction(function(number) {
    return number + 1;
  });
};

// called only from pushOnboarding
export const updateOnboardingMetrics = (question, answer) => {
  const onboardingRef = db.ref("onboardingMetrics");

  if (question == "interest") {
    if (answer == "Both") {
      onboardingMetricsTransaction(onboardingRef, question, "machine-learning");
    }
    let category =
      answer == "Machine Learning" ? "machine-learning" : "web-development";
    onboardingMetricsTransaction(onboardingRef, question, category);
  } else if (question == "motivation") {
    let motivation =
      answer == "Advance my career"
        ? "career"
        : answer == "Explore my interests"
        ? "exploration"
        : "other";

    onboardingMetricsTransaction(onboardingRef, question, motivation);
  } else if (question == "experience") {
    let experience =
      answer == "Not really"
        ? "beginner"
        : answer == "I've played around"
        ? "semi-beginner"
        : answer == "I've built several projects"
        ? "intermediate"
        : "advanced";
    onboardingMetricsTransaction(onboardingRef, question, experience);
  }
};

export const onboardingMetricsTransaction = (ref, question, slug) => {
  ref.child(`${question}/${slug}`).transaction(function(number) {
    return number + 1;
  });
};

export const getOnboardingData = () =>
  db.ref("onboardingMetrics").once("value");

// Maker Log API

export const getMakerLogPosts = callback => {
  let oneWeekAgo = Date.now() - 8 * 7 * 24 * 60 * 60 * 1000;
  db.ref(`makerLog`)
    .orderByChild("datetime")
    .startAt(oneWeekAgo)
    .on("child_added", callback);
};

export const getLast5MakerLogPosts = callback => {
  db.ref(`makerLog`)
    .endAt()
    .limitToLast(5)
    .on("child_added", callback);
};

getLast5MakerLogPosts;

export const pushMakerLogSubmission = (uid, content, imageURL) => {
  updateMetrics("user", uid); // counts as an active user for submitting a maker log post

  db.ref(`makerLog`)
    .orderByChild("uid")
    .equalTo(uid)
    .limitToLast(1)
    .once("value", function(snapshot) {
      // console.log()
      let data = snapshot.val();
      if (data) {
        data = Object.entries(data);
        let datetime = data[0][1].datetime;
        // console.log(datetime);
        // check to increment with date
        if (greaterThanXDays(datetime, Date.now(), 2)) {
          // console.log("greater than two days — streak reset");
          updateStreak(uid, "reset");
        } else if (!greaterThanXDays(datetime, Date.now(), 1)) {
          // console.log("streak not incremented");
        } else {
          // console.log("streak incremented");
          updateStreak(uid, "increment");
        }
      } else {
        // new streak
        // console.log("streak reset to 0!");
        updateStreak(uid, "start");
      }
    })
    .then(() => {
      db.ref(`makerLog`).push({
        uid: uid,
        content: content,
        datetime: Date.now(),
        imageURL: imageURL
      });
    });
  // add enlights for each post
};

export const updateStreak = (uid, command) => {
  // command => "reset" (set to 0), "start" (create entry), "increment" (+1)
  if (command == "start" || command == "reset") {
    db.ref(`users/${uid}`).update({
      streak: 0
    });
  } else if (command == "increment") {
    db.ref(`users/${uid}`)
      .child("streak")
      .transaction(function(streak) {
        return streak + 1;
      });
  }
};

export const getStreak = uid =>
  db
    .ref(`users/${uid}`)
    .child("streak")
    .once("value");

export const greaterThanXDays = (time1, time2, numDays) => {
  // time2 is the latest time, time1 is the last post time
  let date1 = new Date(time1);
  let date2 = new Date(time2);
  let timeDiff = date2.getTime() - date1.getTime(); // difference between current time and last post time
  // console.log("timeDiff: " + timeDiff);
  let endOfDay = new Date(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate() + 1,
    0,
    0,
    0
  );
  // console.log("endOfDay: " + endOfDay);
  let timeLeftInDay = endOfDay.getTime() - date2.getTime(); // time left till end of day
  let XDaysTime = numDays * 24 * 60 * 60 * 1000; // X days time in milliseconds
  let maxTimeDiff = XDaysTime - timeLeftInDay; // the max amount of time that could have elapsed for it to still count as streak
  // console.log("maxTimeDiff: " + maxTimeDiff);
  return timeDiff > maxTimeDiff; // if timeDiff is less than maxTimeDiff then streak still valid
};

export const getUserTags = uid => db.ref(`users/${uid}/tags`).once("value");

export const addUserTag = (uid, tag) => {
  let tagsRef = db.ref(`users/${uid}/tags`);
  tagsRef.transaction(function(tags) {
    if (tags) {
      if (tags.includes(tag)) {
        return tags;
      } else {
        return [...tags, tag];
      }
    } else {
      return [tag];
    }
  });
};

export const addReaction = (key, reactionUid, reaction) => {
  if (!reactionUid) {
    alert("You must be logged in to react to a maker log post!");
  } else {
    let uidRef = db.ref(`makerLog/${key}/${reaction}`);
    uidRef.transaction(function(uids) {
      if (uids) {
        if (uids.includes(reactionUid)) {
          return uids.filter(function(uid) {
            return uid != reactionUid;
          });
        } else {
          return [...uids, reactionUid];
        }
      } else {
        return [reactionUid];
      }
    });
  }
};

// Views API

export const incrementViews = id => {
  let ref = db.ref(`views/views`);
  getViews(id).then(snapshot => {
    let views = snapshot.val();
    ref.update({
      [id]: views + 1
    });
  });
};

export const getViews = id => db.ref(`views/views/${id}`).once("value");

// Dashboard API

export const getResources = cohort =>
  db.ref(`cohort/${cohort.replace(/ /g, "-")}/resources`).once("value");

export const getRecordings = cohort =>
  db.ref(`cohort/${parseCohortTag(cohort)}/recordings`).once("value");

export const getProjectsCompletedByCohortUser = (uid, callback) => {
  db.ref("IBuiltThisSubmissions")
    .orderByChild("uid")
    .equalTo(uid)
    .on("child_added", callback);
};

export const getProjectObjectCompletedByCohortUser = uid =>
  db
    .ref("IBuiltThisSubmissions")
    .orderByChild("uid")
    .equalTo(uid);

export const getDemoDayProjectsCompletedByCohortUser = (uid, callback) => {
  db.ref("CustomIBuiltThisSubmissions")
    .orderByChild("uid")
    .equalTo(uid)
    .on("child_added", callback);
};

export const getDemoDayProjectObjectCompletedByCohortUser = uid =>
  db
    .ref("CustomIBuiltThisSubmissions")
    .orderByChild("uid")
    .equalTo(uid);

export const CustomIBuiltThisSubmission = (
  github,
  demo,
  uid,
  title,
  description,
  img
) => {
  let date = new Date();
  db.ref(`users/${uid}`).once("value", snapshot => {
    let userData = snapshot.val();
    let tags = userData.tags;
    if (tags) {
      db.ref("CustomIBuiltThisSubmissions").push({
        github: github,
        demo: demo,
        uid: uid,
        timestamp: date.getTime(),
        title: title,
        description: description,
        img: img,
        tags: tags
      });
    } else {
      alert("Contact the Enlight Team on discord, something went wrong :(");
    }
  });
};

export const UpdateIBuiltThisSubmission = (
  github,
  demo,
  description,
  img,
  key
) => {
  db.ref(`IBuiltThisSubmissions/${key}`).update({
    github: github,
    demo: demo,
    description: description,
    img: img
  });
};

export const UpdateCustomIBuiltThisSubmission = (
  title,
  github,
  demo,
  description,
  img,
  key
) => {
  db.ref(`CustomIBuiltThisSubmissions/${key}`).update({
    title: title,
    github: github,
    demo: demo,
    description: description,
    img: img
  });
};

export const updateCohortSubmissionMetrics = (projectNumber, type) => {
  // 3 = capstone, others are as you would expect
  let cohortTwoRef = db.ref("cohort/cohort-2");
  if (type == "completion") {
    if (projectNumber == 0) {
      cohortTwoRef
        .child("project-one-completions")
        .transaction(function(number) {
          return number + 1;
        });
    }
    if (projectNumber == 1) {
      cohortTwoRef
        .child("project-two-completions")
        .transaction(function(number) {
          return number + 1;
        });
    }
    if (projectNumber == 2) {
      cohortTwoRef
        .child("project-three-completions")
        .transaction(function(number) {
          return number + 1;
        });
    }
    if (projectNumber == 3) {
      cohortTwoRef.child("capstone-completions").transaction(function(number) {
        return number + 1;
      });
    }
  }
};

// submissions
// cohort/cohort-{ cohortNum }/webinarSubmissions/{ webinarSlug }/{ uid }/{ submission }
// Webinar Metrics
// cohort/cohort-{ cohortNum }/metrics/{ webinarSlug }/{ uid }/{ pushKey }
//                                                                        timeStarted
//                                                                        timeEnded
export const webinarSubmission = (uid, webinarSlug, cohortNum, submission) => {
  let userRef = db.ref(
    `cohort/cohort-${cohortNum}/webinarSubmissions/${webinarSlug}/${uid}`
  );
  userRef.update(submission);
};

export const webinarPageHit = (uid, webinarSlug, cohortNum, timestamp) =>
  db
    .ref(`cohort/cohort-${cohortNum}/metrics/${webinarSlug}/${uid}`)
    .push({ timeStarted: timestamp });

export const webinarPageClose = (
  uid,
  webinarSlug,
  cohortNum,
  timestamp,
  pushKey
) =>
  db
    .ref(`cohort/cohort-${cohortNum}/metrics/${webinarSlug}/${uid}/${pushKey}`)
    .update({ timeEnded: timestamp });

export const toggleOnlineUsers = (uid, cohortNum, webinarSlug, eventType) => {
  let webinarOnlineRef = db.ref(
    `cohort/cohort-${cohortNum}/recordings/${webinarSlug}/online`
  );

  if (eventType == "arrival") {
    webinarOnlineRef.update({
      [uid]: true
    });
  } else if (eventType == "close") {
    webinarOnlineRef.update({
      [uid]: false
    });
  }
};

export const getWebinarOnlineUsers = (cohortNum, webinarSlug) =>
  db.ref(`cohort/cohort-${cohortNum}/recordings/${webinarSlug}/online`);

export const getProjectDates = cohort =>
  db.ref(`cohort/${cohort.replace(/ /g, "-")}/projectDates`);

export const checkUserDataForCohort = userData => {
  if (userData.tags) {
    let cohort = "";
    let found = false;
    userData.tags.map((tag, index) => {
      if (tag.includes("cohort") && !found) {
        cohort = tag;
        found = true;
      }
    });
    return cohort;
  } else {
    return "";
  }
};

export const fetchActiveCohort = () => db.ref("cohort/activeCohort");

export const parseCohortTag = cohort => cohort.replace(/ /g, "-");

export const getCalendarKey = cohort => db.ref(`cohort/${cohort}/calendarKey`);

export const getCohortUids = cohort =>
  db.ref(`cohort/${parseCohortTag(cohort)}/roster`);

// export const setTagsIBuiltThis = () => {
//   db.ref("IBuiltThisSubmissions").once("value", (snapshot) => {
//     let data = snapshot.val();
//     data = Object.keys(data);
//     data.map((key) => {
//       db.ref(`IBuiltThisSubmissions/${key}`).once("value", (snapshot) => {
//         let sub = snapshot.val();
//         let uid = sub.uid;
//         db.ref(`users/${uid}`).once("value", (snapshot) => {
//           let user = snapshot.val();
//           let userTags = user.tags;
//           if(userTags) {
//             db.ref(`IBuiltThisSubmissions/${key}`).update({
//               tags: userTags
//             })
//           }
//         })
//       })
//     })
//   })
// }

// }
// Utility & Backup Functions (not used atm)

// export const addNumericalIdToEachProject = () => {
//   db.ref("webProjects")
//     .once("value")
//     .then(snapshot => {
//       let numericalId = 1;
//       let data = Object.entries(snapshot.val());
//       console.log(data);
//       data.sort((a, b) => (new Date(a[1].date) > new Date(b[1].date) ? 1 : -1));
//       data.forEach(function(project) {
//         let key = project[0];
//         db.ref(`webProjects/${key}`).update({
//           projectId: numericalId
//         });
//         numericalId++
//       });
//     });
// };

// export const copyProjects = () => {
//   db.ref("webProjects").once("value", function (snap) {
//     db.ref("webProjectsBackupJune").set(snap.val(), function (error) {
//       if (error && typeof console !== "undefined" && console.error) {
//         console.error(error);
//       }
//     });
//   });
// };

// export const copyFbRecord = () => {
//   db.ref("enlightsBackup2").once("value", function(snap) {
//     db.ref("enlights").update(snap.val(), function(error) {
//       if (error && typeof console !== "undefined" && console.error) {
//         console.error(error);
//       }
//     });
//   });
// };

// export const addPublishFlag = () => {
//   // DON'T USE, sets all projects in webProjects to have published as true
//   db.ref("webProjects").once("value", function(snapshot) {
//     snapshot.forEach(function(childSnapshot) {
//       var childKey = childSnapshot.key;
//       var childData = childSnapshot.val();
//       db.ref(`webProjects/${childKey}`).update({
//         published: true,
//       });
//     });
//   });
// }

// export const pushMaker = (id, mdxBody, body, image, quote, title, user, published) =>
//   db.ref(`featuredMakers/${id}`).update({
//     mdxBody,
//     id,
//     body,
//     image,
//     quote,
//     title,
//     user,
//     published
//   });

// export const userToIdInEnlights = () => {
//   db.ref("enlightsBackup2").once("value", function(snapshot) {
//     snapshot.forEach(function(childSnapshot) {
//       var childKey = childSnapshot.key;
//       var childData = childSnapshot.val();
//       fetchUserData(childKey).once("value", (snapshot) => {
//         let data = snapshot.val();
//         data = Object.keys(data);
//         let uid = data[0];
//         console.log(childData);
//         db.ref(`enlightsBackup2/${uid}`).update({
//           enlights: childData,
//         });
//         db.ref(`enlightsBackup2/${childKey}`).remove();
//       });
//     });
//   });
// };

export const addTagToCohort = arrayOfUsernames => {
  arrayOfUsernames.map((id, index) => {
    fetchUserData(id).once("value", snapshot => {
      // console.log(snapshot.val())
      let uid = Object.keys(snapshot.val())[0];
      // console.log(`${id}:  ${uid}`);
      addUserTag(uid, "cohort 1"); // change "cohort 0" to wanted tag
    });
  });
};

export const fetchUserDataByEmail = email =>
  db
    .ref("users")
    .orderByChild("email_address")
    .equalTo(email);

export const addTagToCohortByEmail = (arrayOfEmails, tag) => {
  arrayOfEmails.map((email, index) => {
    if (email != "") {
      fetchUserDataByEmail(email).once("value", snapshot => {
        let userData = snapshot.val();
        if (userData && userData != undefined) {
          userData = Object.values(userData)[0];
          let uid = Object.keys(snapshot.val())[0];
          addUserTag(uid, tag); // change "cohort 0" to wanted tag
          if (
            tag.includes("cohort") &&
            (!userData.tags ||
              userData.tags == undefined ||
              (!userData.tags.includes("team") &&
                !userData.tags.includes("mentor")))
          ) {
            addUidToRoster(uid, tag);
          } else {
            console.log("Not cohort tag");
          }
        } else {
          console.log(`ERROR: ${email}`);
        }
      });
    } else {
      console.log(`ERROR (blank email): ${email}`);
    }
  });
  alert("Added tag to all emails");
};

export const removeTagByEmail = (arrayOfEmails, tag) => {
  arrayOfEmails.map((email, index) => {
    if (email != "") {
      fetchUserDataByEmail(email).once("value", snapshot => {
        let userData = snapshot.val();
        if (userData && userData != undefined) {
          let uid = Object.keys(snapshot.val())[0];
          removeUserTag(uid, tag);
        } else {
          console.log(`ERROR: ${email}`);
        }
      });
    } else {
      console.log(`ERROR (blank email): ${email}`);
    }
  });
  alert("Removed tag from all emails");
};

const removeUserTag = (uid, tag) => {
  let tagsRef = db.ref(`users/${uid}/tags`);
  tagsRef.transaction(function(tags) {
    if (tags) {
      if (tags.includes(tag)) {
        let index = tags.indexOf(tag);
        tags.splice(index, 1);
        return tags;
      } else {
        return tags;
      }
    } else {
      return null;
    }
  });
};

export const addUidToRoster = (uid, cohort) => {
  let cohortParsed = parseCohortTag(cohort);
  let rosterRef = db.ref(`cohort/${cohortParsed}/roster`);
  rosterRef.transaction(function(uids) {
    if (uids) {
      if (uids.includes(uid)) {
        return uids;
      } else {
        return [...uids, uid];
      }
    } else {
      return [uid];
    }
  });
};

export const fetchWebinarData = (webinarSlug, cohortNum) =>
  db.ref(`cohort/cohort-${cohortNum}/recordings/${webinarSlug}`);

export const fetchCohortMetrics = cohortNum =>
  db.ref(`cohort/cohort-${cohortNum}/metrics`);

// Courses API

export const addCourseToUser = (uid, course) => {
  let courseRef = db.ref(`users/${uid}/courses`);
  courseRef.transaction(function(courses) {
    if (courses) {
      if (courses.includes(course)) {
        return courses;
      } else {
        return [...courses, course];
      }
    } else {
      return [course];
    }
  });
};

export const addUserToCourseRoster = (uid, course) => {
  let rosterRef = db.ref(`courses/${course}/roster`);
  rosterRef.transaction(function(uids) {
    if (uids) {
      if (uids.includes(uid)) {
        return uids;
      } else {
        return [...uids, uid];
      }
    } else {
      return [uid];
    }
  });
};

export const pushWebDevelopmentProjectCompletion = (
  index,
  uid,
  milestone,
  date,
  demo,
  description,
  github,
  img,
  numericalId,
  postId,
  title,
  custom
) => {
  if (!github.match(/^[a-zA-Z]+:\/\//) && github != "") {
    github = "https://" + github;
  }
  if (!demo.match(/^[a-zA-Z]+:\/\//) && demo != "") {
    demo = "https://" + demo;
  }

  db.ref(
    `courses/web-development/${index}/completions/${uid}/${milestone}`
  ).update({
    uid: uid,
    milestone: milestone,
    date: date,
    demo: demo,
    description: description,
    github: github,
    img: img,
    numericalId: numericalId,
    postId: postId,
    title: title
  });

  if (!custom) {
    db.ref("IBuiltThisSubmissions").push({
      github: github,
      demo: demo,
      uid: uid,
      date: date,
      title: title,
      postId: postId,
      description: description,
      img: img,
      numericalId: numericalId
    });
  } else {
    // custom project for project 3
    db.ref("CustomIBuiltThisSubmissions").push({
      github: github,
      demo: demo,
      uid: uid,
      date: date,
      title: "Capstone Project",
      description: description,
      img: img
    });
  }
};

export const pushCourseWorkshopCompletion = (
  index,
  uid,
  workshop,
  date,
  demo,
  img,
  title,
  course
) => {
  if (!demo.match(/^[a-zA-Z]+:\/\//) && demo != "") {
    demo = "https://" + demo;
  }
  db.ref(`courses/${course}/${index}/completions/${uid}/${workshop}`).update({
    uid: uid,
    milestone: workshop,
    date: date,
    demo: demo,
    img: img,
    title: title
  });
};

export const pushPrototypeCompletion = (
  index,
  uid,
  workshop,
  date,
  demo,
  description,
  img,
  prototypeName,
  title,
  course
) => {
  if (!demo.match(/^[a-zA-Z]+:\/\//) && demo != "") {
    demo = "https://" + demo;
  }
  db.ref(`courses/${course}/${index}/completions/${uid}/${workshop}`).update({
    uid: uid,
    milestone: workshop,
    date: date,
    demo: demo,
    description: description,
    img: img,
    prototypeName: prototypeName,
    title: title
  });
};

export const getCourseCohortCompletions = (course, index, uid, callback) =>
  db
    .ref(`courses/${course}/${index}/completions/${uid}`)
    .on("child_added", callback);

export const getCourseCohortCompletionsOnce = (course, index, uid, callback) =>
  db.ref(`courses/${course}/${index}/completions/${uid}`).once("value");

// Webinar Share API (Non-auth)
export const addPromotionalWorkshopCompletion = (name, url, img) => {
  db.ref(`workshopPromotions/hacklytics`).push({
    name: name,
    url: url,
    img: img
  });
};

export const getPromotionalWorkshopCompletions = callback => {
  db.ref(`workshopPromotions/hacklytics`).on("child_added", callback);
};

export const getCohortMilestonesById = (course, index, id) =>
  db.ref(`courses/${course}/${index}/milestones/${id}`).once("value");

export const getCourseCohortData = course =>
  db.ref(`courses/${course}/cohortData`).once("value");

export const getCourseCurrentCohort = (course, index) =>
  db.ref(`courses/${course}/${index}/cohortData`).once("value");

export const getCourseResources = course =>
  db.ref(`courses/${course}/resources`).once("value");

export const getMilestoneCompletionDemo = (course, index, uid, milestone) =>
  db
    .ref(`courses/${course}/${index}/completions/${uid}/${milestone}`)
    .once("value");

// Instructor Dashboard APIs
export const getCohortRoster = (course, index) =>
  db.ref(`courses/${course}/${index}/roster`).once("value");

export const getCohortCompletions = (course, index) =>
  db.ref(`courses/${course}/${index}/completions`).once("value");

export const getCohortMilestones = (course, index) =>
  db.ref(`courses/${course}/${index}/milestones`).once("value");

// export const copyWebDevelopmentCohort = () => {
//   db.ref('courses/web-development/1/milestones').once("value", function (snap) {
//     db.ref("courses/web-development/2/milestones").set(snap.val(), function (error) {
//       if (error && typeof console !== "undefined" && console.error) {
//         console.error(error);
//       }
//     });
//   });
// };

// export const copyFigmaCohort = () => {
//   db.ref('courses/web-development/1/milestones').once("value", function (snap) {
//     db.ref("courses/figma/0/milestones").set(snap.val(), function (error) {
//       if (error && typeof console !== "undefined" && console.error) {
//         console.error(error);
//       }
//     });
//   });
// };

// export const deleteRiley = () => {
//   db.ref("projectSubmissions").orderByChild("author_username").equalTo("riley").once("value", (snapshot) => {
//     let data = Object.keys(snapshot.val());
//     data.map((key) => {
//       console.log(key);
//       db.ref(`projectSubmissions/${key}`).remove();
//     })
//   })

// }

// fixing broken IBuiltThis
// IBuiltThis had only postId and we had changed it to numericalId. This changes postId back to projectSlug and adds a new entry for numericalId
// export const getProjectFromNumericalId = (numericalId) => db.ref("webProjects").orderByChild("projectId").equalTo(numericalId).once("value");

// export const getBadIBuiltThis = () => db.ref("IBuiltThisSubmissionsBackupJuly").once("value")

// export const fixIBuiltThisEntry = (pushKey) => {
//   let entryRef = db.ref(`IBuiltThisSubmissionsBackupJuly/${pushKey}`);
//   entryRef.once("value", snapshot => {
//     let submission = snapshot.val();
//     if (!isNaN(submission.postId)) {
//       var numericalId = submission.postId;
//       getProjectFromNumericalId(submission.postId).then(snapshot => {
//         let project = Object.values(snapshot.val());
//         let projectSlug = project[0].id;
//         entryRef.update({
//           postId: projectSlug,
//           numericalId: numericalId
//         })
//       })
//     }
//   });
// }

// add image entry to all IBuiltThis Entries
// export const addImgToIBTEntry = (pushKey) => {
//   let entryRef = db.ref(`IBuiltThisSubmissionsBackupJuly/${pushKey}`);
//   entryRef.once("value", snapshot => {
//     let submission = snapshot.val();
//     if (!(submission.img)) {
//       entryRef.update({
//         img: ""
//       })
//     }
//   });
// }
