import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
// import { TwitterIcon, EmailIcon } from "react-share";

// import { usersOnline } from "api/db";

// import AuthUserContext from "../components/Session/AuthUserContext";

class FooterComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usersOnline: "0",
    };
  }

  // componentDidMount() {
  //   usersOnline().then(snapshot => {
  //     let onlineUsers = 0;
  //     snapshot.forEach(function() {
  //       onlineUsers++;
  //     });
  //     this.setState(() => ({ usersOnline: onlineUsers }));
  //   });
  // }

  render() {
    // const onlineUsers = (
    //   <AuthUserContext.Consumer>
    //     {authUser =>
    //       authUser ? <p> {this.state.usersOnline} users online </p> : null
    //     }
    //   </AuthUserContext.Consumer>
    // );
    return (
      <footer className="text-gray-700 body-font">
        <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-no-wrap flex-wrap flex-col">
          <div className="w-72 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
            <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
              <span className="text-2xl playfair text-blue-600">enlight</span>
            </a>
            <p className="mt-2 text-sm text-gray-500">
              Enlight is the educational platform for cohort-based courses
              focused on development. We provide the best-in-class education
              paired with a supportive community and accountability.
              {/* Enlight is the educational network to learn, build, and share
              programming projects. We provide the best-in-class education
              paired with a supportive community and accountability. */}
            </p>
          </div>
          <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
            <div className="lg:w-2/5 md:w-1/2 w-full px-4"></div>
            <div className="lg:w-1/5 md:w-1/2 w-full px-4">
              <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
                PROJECTS
              </h2>
              <nav className="list-none mb-10">
                <li>
                  <Link to="/category/web-development"
                    className="text-gray-600 hover:text-gray-800">
                      Web Development
                  </Link>
                </li>
                <li>
                  <Link to="/category/data-science"
                    className="text-gray-600 hover:text-gray-800">
                      Data Science
                  </Link>
                </li>
                <li>
                  <Link to="/projects"
                    className="text-gray-600 hover:text-gray-800">
                      All Projects
                  </Link>
                </li>
                {/* <li>
                  <a className="text-gray-600 hover:text-gray-800">Fourth Link</a>
                </li> */}
              </nav>
            </div>
            {/* <div className="lg:w-1/5 md:w-1/2 w-full px-4">
              <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
                BY LANGUAGE
              </h2>
              <nav className="list-none mb-10">
                <li>
                  <a
                    className="text-gray-600 hover:text-gray-800"
                    href="/language/html-css-js"
                  >
                    HTML/CSS/JS
                  </a>
                </li>
                <li>
                  <a
                    className="text-gray-600 hover:text-gray-800"
                    href="/language/node-js"
                  >
                    Node.js
                  </a>
                </li>
                <li>
                  <a
                    className="text-gray-600 hover:text-gray-800"
                    href="/language/react-js"
                  >
                    React
                  </a>
                </li>
                <li>
                  <a
                    className="text-gray-600 hover:text-gray-800"
                    href="/language/python"
                  >
                    Python
                  </a>
                </li>
              </nav>
            </div>
            <div className="lg:w-1/5 md:w-1/2 w-full px-4">
              <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
                BY DIFFICULTY
              </h2>
              <nav className="list-none mb-10">
                <li>
                  <a
                    className="text-gray-600 hover:text-gray-800"
                    href="/difficulty/beginner"
                  >
                    Beginner
                  </a>
                </li>
                <li>
                  <a
                    className="text-gray-600 hover:text-gray-800"
                    href="/difficulty/intermediate"
                  >
                    Intermediate
                  </a>
                </li>
                <li>
                  <a
                    className="text-gray-600 hover:text-gray-800"
                    href="/difficulty/advanced"
                  >
                    Advanced
                  </a>
                </li>
                {/* <li>
                  <a className="text-gray-600 hover:text-gray-800">Fourth Link</a>
                </li> 
              </nav>
            </div> */}
            <div className="lg:w-1/5 md:w-1/2 w-full px-4">
              <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
                QUICK LINKS
              </h2>
              <nav className="list-none mb-10">
                <li>
                  <Link to="/courses/web-development"
                    className="text-gray-600 hover:text-gray-800">
                      Web Development
                  </Link>
                </li>
                <li>
                  <Link to="/courses/figma"
                    className="text-gray-600 hover:text-gray-800">
                      Figma 
                  </Link>
                </li>

                <li>
                  <Link to="/community"
                    className="text-gray-600 hover:text-gray-800">
                      Community
                  </Link>
                </li>

                <li>
                  <Link to="/contribute"
                    className="text-gray-600 hover:text-gray-800">
                      Contribute
                  </Link>
                </li>
                {/* <li>
                  <a className="text-gray-600 hover:text-gray-800" href="https://enlight.nyc/about">About</a>
                </li> */}
              </nav>
            </div>
            <div className="lg:w-1/5 md:w-1/2 w-full px-4">
              <h2 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
                ABOUT
              </h2>
              <nav className="list-none mb-10">
                <li>
                  <Link to="/about"
                    className="text-gray-600 hover:text-gray-800">
                      About
                  </Link>
                </li>
                <li>
                  <Link to="/terms"
                    className="text-gray-600 hover:text-gray-800">
                      Terms of Service
                  </Link>
                </li>
                <li>
                  <Link to="/privacy"
                    className="text-gray-600 hover:text-gray-800">
                      Privacy Policy
                  </Link>
                </li>
                {/* <li>
                  <a className="text-gray-600 hover:text-gray-800">Fourth Link</a>
                </li> */}
              </nav>
            </div>
          </div>
        </div>
        <div className="bg-gray-200">
          <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
            <p className="text-gray-500 text-sm text-center sm:text-left">
              © 2021 Enlight —
              <a
                href="https://twitter.com/tryenlight"
                rel="noopener noreferrer"
                className="text-gray-600 ml-1"
                target="_blank"
              >
                @tryenlight
              </a>
            </p>
            <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
              <a
                href="https://twitter.com/tryenlight"
                className="text-gray-500"
              >
                <svg
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                </svg>
              </a>
              <a
                href="https://instagram.com/tryenlight"
                className="ml-3 text-gray-500"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/enlight-edu"
                className="ml-3 text-gray-500"
              >
                <svg
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="0"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="none"
                    d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                  ></path>
                  <circle cx="4" cy="4" r="2" stroke="none"></circle>
                </svg>
              </a>
            </span>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterComponent;
