import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";

const prodConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: "app.enlight.nyc",
  databaseURL: "https://enlight-9b523.firebaseio.com",
  projectId: "enlight-9b523",
  storageBucket: "enlight-9b523.appspot.com",
  messagingSenderId: "785651928454"
};

const devConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: "app.enlight.nyc", //enlight-9b523.firebaseapp.com
  databaseURL: "https://enlight-9b523.firebaseio.com",
  projectId: "enlight-9b523",
  storageBucket: "enlight-9b523.appspot.com",
  messagingSenderId: "785651928454"
};

const config = process.env.NODE_ENV === "production" ? prodConfig : devConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

let db, auth, storage;

if (typeof window !== "undefined") {
  db = firebase.database();
  auth = firebase.auth();
  storage = firebase.storage();
}

const GoogleAuthProvider = new firebase.auth.GoogleAuthProvider();
const GithubAuthProvider = new firebase.auth.GithubAuthProvider();
GithubAuthProvider.addScope('user');
const TwitterAuthProvider = new firebase.auth.TwitterAuthProvider();
export {
  db,
  auth,
  storage,
  GoogleAuthProvider,
  GithubAuthProvider,
  TwitterAuthProvider
};
