import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import { auth } from "api/firebase";
import { fetchUserDataByUid } from "api/db";

const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [banner, setBanner] = useState(true);
  const [authUserData, setAuthUserData] = useState(null);
  const [showCohorts, setShowCohorts] = useState(false);
  const [toggleActive, setToggleActive] = useState(false);
  const [dashboardToggle, setDashboardToggle] = useState(false);
  const [showDashboard, setDashboard] = useState(false);
  const locRef = useRef({ location: null });

  useEffect(() => {
    let userData;
    if (typeof window != "undefined") {
      userData = JSON.parse(sessionStorage.getItem("userData"));
      setAuthUserData(userData);
    }

    if (!authUserData && auth && auth.currentUser && auth.currentUser.uid) {
      fetchUserDataByUid(auth.currentUser.uid).once("value", function(
        snapshot
      ) {
        setAuthUserData(snapshot.val());
      });
    }
  }, [auth && auth.currentUser]);

  // useEffect(() => {
  //   if (!locRef.current.location) locRef.current.location = location;
  //   // then make sure dialog is closed on route change
  //   else if (locRef.current.location.pathname.includes("courses")) {
  //     if (banner) setBanner(false);
  //     locRef.current.location = location;
  //   } else {
  //     if (!banner) setBanner(true);
  //     locRef.current.location = location;
  //   }
  // });

  return (
    <>
      {/* {banner && (
        <Link to="/courses/web-development">
          <div
            className="p-3 bg-blue-600 hover:bg-blue-500 z-100 w-full shadow relative flex justify-center items-center"
            onClick={() => {
              setBanner(false);
            }}
          >
            <div
              className="m-2 items-center text-blue-100 bg-blue-800 leading-none rounded-full flex inline-flex cursor-pointer"
              role="alert"
            >
              <span className="flex rounded-full uppercase p-2 text-xs font-bold mr-2 ml-2">
                NEW
              </span>
            </div>
            <div className="text-center text-white inline ">
              Our two-week Web Development cohort is starting soon!
              <span className="font-bold"> Learn more &#8250;</span>
            </div>
          </div>
        </Link>
      )} */}
      {/* {banner && (
        <a
          href="https://www.producthunt.com/posts/enlight-cohorts"
          target="_blank"
        >
          <div
            className="p-3 bg-yellow-600 hover:bg-yellow-500 z-100 w-full shadow relative flex justify-center items-center"
            onClick={() => {
              setBanner(false);
              trackClick("sitewide banner", "Web Dev Course");
            }}
          >
            <div
              className="m-2 items-center text-yellow-100 bg-yellow-800 leading-none rounded-full flex inline-flex cursor-pointer"
              role="alert"
            >
              <span className="flex rounded-full uppercase p-2 text-xs font-bold mr-2 ml-2">
                Announcement
              </span>
            </div>
            <div className="text-center text-white inline ">
              Enlight is live on Product Hunt! — 
              <span className="font-bold">Check us out &#8250;</span>
            </div>
          </div>
        </a>
      )} */}
      <div className="max-w-screen-xl mx-auto">
        <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
          <nav className="relative flex items-center justify-between sm:h-10">
            <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
              <div className="flex items-center justify-between w-full md:w-auto text-4xl playfair text-blue-600 hover:text-blue-500">
                <Link to="/" aria-label="Home">
                  enlight
                </Link>
                <div className="-mr-2 flex items-center md:hidden">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-600 transition duration-150 ease-in-out"
                    id="main-menu"
                    aria-label="Main menu"
                    aria-haspopup="true"
                    onClick={() => {
                      setMobileMenu(true);
                      setToggleActive(false);
                      setShowCohorts(false);
                      setDashboardToggle(false);
                    }}
                  >
                    <svg
                      className="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="hidden md:block lg:ml-10 md:ml-6 md:pr-4 z-20">
              <div
                onMouseEnter={() => setShowCohorts(!showCohorts)}
                onMouseLeave={() => setShowCohorts(!showCohorts)}
                className="group inline-block"
              >
                <div
                  aria-haspopup="true"
                  aria-expanded={showCohorts}
                  className="cursor-pointer"
                >
                  <span className="lg:ml-8 md:ml-5 font-medium text-gray-600 group-hover:text-gray-900 transition duration-150 ease-in-out">
                    Cohorts
                  </span>
                  <svg
                    viewBox="0 0 20 20"
                    className="inline fill-current text-gray-600 group-hover:text-gray-900 w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1 group-hover:rotate-180 ease-in-out lg:mr-6 md:mr-4"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                {showCohorts ? (
                  <div className="absolute">
                    <div
                      className="flex flex-col ml-4 px-4 py-2 space-y-2 bg-white rounded-md shadow"
                      aria-orientation="vertical"
                    >
                      <Link
                        to="/courses/web-development"
                        className="font-medium pt-1 text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                      >
                        Web Development
                      </Link>
                      <Link
                        to="/courses/figma"
                        className="font-medium pb-1 text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                      >
                        Prototyping with Figma
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>

              <Link
                to="/projects"
                className="font-medium text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
              >
                Projects
              </Link>

              {auth && auth.currentUser ? (
                <>
                  {(authUserData &&
                    authUserData.courses &&
                    authUserData.courses.find((s) => s.includes("wd")) &&
                    authUserData.courses.find((s) => s.includes("f"))) ||
                  (authUserData &&
                    authUserData.tags &&
                    (authUserData.tags.includes("team") ||
                      authUserData.tags.includes("mentor"))) ||
                  (authUserData && authUserData.instructor) ? (
                    <div
                      onMouseEnter={() => setDashboard(!showDashboard)}
                      onMouseLeave={() => setDashboard(!showDashboard)}
                      className="group inline-block"
                    >
                      <div
                        aria-haspopup="true"
                        aria-expanded={showDashboard}
                        className="cursor-pointer"
                      >
                        <span className="lg:ml-8 md:ml-5 font-medium text-gray-600 group-hover:text-gray-900 transition duration-150 ease-in-out">
                          Dashboard
                        </span>
                        <svg
                          viewBox="0 0 20 20"
                          className="inline fill-current text-gray-600 group-hover:text-gray-900 w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1 group-hover:rotate-180 ease-in-out lg:mr-6 md:mr-4"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      {showDashboard ? (
                        <div className="absolute">
                          <div
                            className="flex flex-col ml-4 px-4 py-2 space-y-2 bg-white rounded-md shadow"
                            aria-orientation="vertical"
                          >
                            {((authUserData &&
                              authUserData.courses &&
                              authUserData.courses.find((s) =>
                                s.includes("wd")
                              )) ||
                              (authUserData.tags &&
                                authUserData.tags.find(
                                  (s) =>
                                    s.includes("mentor") || s.includes("team")
                                ))) && (
                              <Link
                                to="/courses/web-development/dashboard"
                                className="font-medium pt-1 text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                              >
                                Web Development
                              </Link>
                            )}
                            {((authUserData &&
                              authUserData.courses &&
                              authUserData.courses.find((s) =>
                                s.includes("f")
                              )) ||
                              (authUserData.tags &&
                                authUserData.tags.find(
                                  (s) =>
                                    s.includes("mentor") || s.includes("team")
                                ))) && (
                              <Link
                                to="/courses/figma/dashboard"
                                className="font-medium pb-1 text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                              >
                                Figma
                              </Link>
                            )}

                            {(authUserData &&
                              authUserData.tags &&
                              authUserData.tags.includes("team")) ||
                            (authUserData &&
                              authUserData.instructor &&
                              authUserData.instructor.find((s) =>
                                s.includes("f")
                              ) &&
                              authUserData.instructor.find((s) =>
                                s.includes("wd")
                              )) ? (
                              <>
                                <Link
                                  to="/courses/figma/admin/dashboard"
                                  className="font-medium pb-1 text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                                >
                                  Figma (Instructor)
                                </Link>
                                <Link
                                  to="/courses/web-development/admin/dashboard"
                                  className="font-medium pb-1 text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                                >
                                  Web Development (Instructor)
                                </Link>
                              </>
                            ) : (
                              <>
                                {authUserData &&
                                  authUserData.instructor &&
                                  authUserData.instructor.find((s) =>
                                    s.includes("f")
                                  ) && (
                                    <Link
                                      to="/courses/figma/admin/dashboard"
                                      className="font-medium pb-1 text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                                    >
                                      Figma (Instructor)
                                    </Link>
                                  )}
                                {authUserData &&
                                  authUserData.instructor &&
                                  authUserData.instructor.find((s) =>
                                    s.includes("w")
                                  ) && (
                                    <Link
                                      to="/courses/web-development/admin/dashboard"
                                      className="font-medium pb-1 text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                                    >
                                      Web Development (Instructor)
                                    </Link>
                                  )}
                              </>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <>
                      {authUserData &&
                        authUserData.courses &&
                        authUserData.courses.find((s) => s.includes("wd")) && (
                          <Link
                            to="/courses/web-development/dashboard"
                            className="lg:ml-8 md:ml-5 font-medium pt-1 text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                          >
                            Dashboard
                          </Link>
                        )}
                      {authUserData &&
                        authUserData.courses &&
                        authUserData.courses.find((s) => s.includes("f")) && (
                          <Link
                            to="/courses/figma/dashboard"
                            className="lg:ml-8 md:ml-5 font-medium pt-1 text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                          >
                            Dashboard
                          </Link>
                        )}
                      {authUserData &&
                        authUserData.instructor &&
                        authUserData.instructor.find((s) =>
                          s.includes("wd")
                        ) && (
                          <Link
                            to="/courses/web-development/admin/dashboard"
                            className="lg:ml-8 md:ml-5 font-medium pt-1 text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                          >
                            Dashboard
                          </Link>
                        )}
                      {authUserData &&
                        authUserData.instructor &&
                        authUserData.instructor.find((s) =>
                          s.includes("f")
                        ) && (
                          <Link
                            to="/courses/figma/admin/dashboard"
                            className="lg:ml-8 md:ml-5 font-medium pt-1 text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                          >
                            Dashboard
                          </Link>
                        )}
                    </>
                  )}
                </>
              ) : null}
              {(authUserData &&
                authUserData.courses &&
                authUserData.courses.find((s) => s.includes("wd")) &&
                authUserData.courses.find((s) => s.includes("f"))) ||
              (authUserData &&
                authUserData.tags &&
                (authUserData.tags.includes("team") ||
                  authUserData.tags.includes("mentor"))) ||
              (authUserData && authUserData.instructor) ? (
                <>
                  <Link
                    to="/community"
                    className="font-medium text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  >
                    Community
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/community"
                    className="lg:ml-8 md:ml-5 font-medium text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  >
                    Community
                  </Link>
                </>
              )}
              <Link
                to="/gallery"
                className="lg:ml-8 md:ml-5 font-medium text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
              >
                Gallery
              </Link>

              {auth && auth.currentUser ? (
                <Link to="/account">
                  <div className="lg:ml-8 md:ml-5 inline-block align-middle hover:opacity-75 transition duration-150 ease-in-out">
                    <img
                      src={
                        authUserData && authUserData.photoURL
                          ? authUserData.photoURL
                          : "/img/avatar.png"
                      }
                      className="rounded-full h-10 w-10"
                    />
                  </div>
                </Link>
              ) : (
                <>
                  <Link
                    to="/login"
                    className="lg:ml-8 md:ml-5 font-medium text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out"
                  >
                    Login
                  </Link>
                  <Link
                    to="/signup"
                    className="lg:ml-8 md:ml-5 font-medium bg-gray-100 rounded p-3 text-blue-600 hover:text-blue-900 transition duration-150 ease-in-out"
                  >
                    Sign up &#8250;
                  </Link>
                </>
              )}
            </div>
          </nav>
        </div>
        {mobileMenu ? (
          <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-10">
            <div className="rounded-lg shadow-md">
              <div
                className="rounded-lg bg-white shadow-xs overflow-hidden"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="main-menu"
              >
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div
                    className="text-4xl playfair text-blue-600"
                    onClick={() => {
                      setMobileMenu(false);
                      setToggleActive(false);
                      setDashboardToggle(false);
                    }}
                  >
                    <Link to="/" aria-label="Home">
                      enlight
                    </Link>
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-600 transition duration-150 ease-in-out"
                      aria-label="Close menu"
                      onClick={() => {
                        setMobileMenu(false);
                      }}
                    >
                      <svg
                        className="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3">
                  <div
                    onClick={() => setShowCohorts(!showCohorts)}
                    className="group inline-block mt-1 px-3 py-2"
                  >
                    <div
                      aria-haspopup="true"
                      aria-expanded={showCohorts}
                      className="cursor-pointer"
                      onClick={() => setToggleActive(!toggleActive)}
                    >
                      <span className=" rounded-md text-base font-medium text-gray-600 group-hover:text-gray-900 hover:bg-gray-50 group-focus:outline-none group-focus:text-gray-900 group-focus:bg-gray-50 transition duration-150 ease-in-out">
                        Cohorts
                      </span>
                      <svg
                        viewBox="0 0 20 20"
                        className={`inline fill-current text-gray-600 group-hover:text-gray-900 w-4 h-4 mb-1 transition-transform duration-200 ease-in-out transform ${
                          toggleActive ? "rotate-180" : "rotate-0"
                        }`}
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    {showCohorts ? (
                      <div className="bg-gray-100 shadow rounded-md">
                        <Link
                          to="/courses/web-development"
                          role="menuitem"
                          className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                          onClick={() => {
                            setMobileMenu(false);
                            setToggleActive(false);
                          }}
                        >
                          Web Development
                        </Link>
                        <Link
                          to="/courses/figma"
                          role="menuitem"
                          className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                          onClick={() => {
                            setMobileMenu(false);
                            setToggleActive(false);
                          }}
                        >
                          Prototyping in Figma
                        </Link>{" "}
                      </div>
                    ) : null}
                  </div>
                  <Link
                    to="/projects"
                    role="menuitem"
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    onClick={() => {
                      setMobileMenu(false);
                      setToggleActive(false);
                    }}
                  >
                    Projects
                  </Link>

                  {auth && auth.currentUser ? (
                    <>
                      {(authUserData &&
                        authUserData.courses &&
                        authUserData.courses.find((s) => s.includes("wd")) &&
                        authUserData.courses.find((s) => s.includes("f"))) ||
                      (authUserData &&
                        authUserData.tags &&
                        (authUserData.tags.includes("team") ||
                          authUserData.tags.includes("mentor"))) ||
                      (authUserData && authUserData.instructor) ? (
                        <div className="">
                          <div
                            onClick={() => setDashboard(!showDashboard)}
                            className="group inline-block mt-1 px-3 py-2"
                          >
                            <div
                              aria-haspopup="true"
                              aria-expanded={showDashboard}
                              className="cursor-pointer"
                              onClick={() =>
                                setDashboardToggle(!dashboardToggle)
                              }
                            >
                              <span className="rounded-md text-base font-medium text-gray-600 group-hover:text-gray-900 hover:bg-gray-50 group-focus:outline-none group-focus:text-gray-900 group-focus:bg-gray-50 transition duration-150 ease-in-out">
                                Dashboard
                              </span>
                              <svg
                                viewBox="0 0 20 20"
                                className={`inline fill-current text-gray-600 group-hover:text-gray-900 w-4 h-4 mb-1 transition-transform duration-200 ease-in-out transform ${
                                  dashboardToggle ? "rotate-180" : "rotate-0"
                                }`}
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                            </div>
                            {showDashboard ? (
                              <div className="bg-gray-100 shadow rounded-md">
                                {((authUserData &&
                                  authUserData.courses &&
                                  authUserData.courses.find((s) =>
                                    s.includes("wd")
                                  )) ||
                                  (authUserData.tags &&
                                    authUserData.tags.find(
                                      (s) =>
                                        s.includes("mentor") ||
                                        s.includes("team")
                                    ))) && (
                                  <Link
                                    to="/courses/web-development/dashboard"
                                    className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                                    onClick={() => {
                                      setMobileMenu(false);
                                      setDashboardToggle(false);
                                    }}
                                  >
                                    Web Development
                                  </Link>
                                )}
                                {((authUserData &&
                                  authUserData.courses &&
                                  authUserData.courses.find((s) =>
                                    s.includes("f")
                                  )) ||
                                  (authUserData.tags &&
                                    authUserData.tags.find(
                                      (s) =>
                                        s.includes("mentor") ||
                                        s.includes("team")
                                    ))) && (
                                  <Link
                                    to="/courses/figma/dashboard"
                                    className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                                    onClick={() => {
                                      setMobileMenu(false);
                                      setDashboardToggle(false);
                                    }}
                                  >
                                    Figma
                                  </Link>
                                )}

                                {(authUserData &&
                                  authUserData.tags &&
                                  authUserData.tags.includes("team")) ||
                                (authUserData &&
                                  authUserData.instructor &&
                                  authUserData.instructor.find((s) =>
                                    s.includes("f")
                                  ) &&
                                  authUserData.instructor.find((s) =>
                                    s.includes("wd")
                                  )) ? (
                                  <>
                                    <Link
                                      to="/courses/figma/admin/dashboard"
                                      className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                                      onClick={() => {
                                        setMobileMenu(false);
                                        setDashboardToggle(false);
                                      }}
                                    >
                                      Figma (Instructor)
                                    </Link>
                                    <Link
                                      to="/courses/web-development/admin/dashboard"
                                      className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                                      onClick={() => {
                                        setMobileMenu(false);
                                        setDashboardToggle(false);
                                      }}
                                    >
                                      Web Development (Instructor)
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    {authUserData &&
                                      authUserData.instructor &&
                                      authUserData.instructor.find((s) =>
                                        s.includes("f")
                                      ) && (
                                        <Link
                                          to="/courses/figma/admin/dashboard"
                                          className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                                          onClick={() => {
                                            setMobileMenu(false);
                                            setDashboardToggle(false);
                                          }}
                                        >
                                          Figma (Instructor)
                                        </Link>
                                      )}
                                    {authUserData &&
                                      authUserData.instructor &&
                                      authUserData.instructor.find((s) =>
                                        s.includes("w")
                                      ) && (
                                        <Link
                                          to="/courses/web-development/admin/dashboard"
                                          className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                                          onClick={() => {
                                            setMobileMenu(false);
                                            setDashboardToggle(false);
                                          }}
                                        >
                                          Web Development (Instructor)
                                        </Link>
                                      )}
                                  </>
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <>
                          {authUserData &&
                            authUserData.courses &&
                            authUserData.courses.find((s) =>
                              s.includes("wd")
                            ) && (
                              <Link
                                to="/courses/web-development/dashboard"
                                className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                                onClick={() => {
                                  setMobileMenu(false);
                                  setDashboardToggle(false);
                                }}
                              >
                                Dashboard
                              </Link>
                            )}
                          {authUserData &&
                            authUserData.courses &&
                            authUserData.courses.find((s) =>
                              s.includes("f")
                            ) && (
                              <Link
                                to="/courses/figma/dashboard"
                                className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                                onClick={() => {
                                  setMobileMenu(false);
                                  setDashboardToggle(false);
                                }}
                              >
                                Dashboard
                              </Link>
                            )}
                          {authUserData &&
                            authUserData.instructor &&
                            authUserData.instructor.find((s) =>
                              s.includes("wd")
                            ) && (
                              <Link
                                to="/courses/web-development/admin/dashboard"
                                className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                                onClick={() => {
                                  setMobileMenu(false);
                                  setDashboardToggle(false);
                                }}
                              >
                                Dashboard
                              </Link>
                            )}
                          {authUserData &&
                            authUserData.instructor &&
                            authUserData.instructor.find((s) =>
                              s.includes("f")
                            ) && (
                              <Link
                                to="/courses/figma/admin/dashboard"
                                className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                                onClick={() => {
                                  setMobileMenu(false);
                                  setDashboardToggle(false);
                                }}
                              >
                                Dashboard
                              </Link>
                            )}
                        </>
                      )}
                    </>
                  ) : null}

                  <Link
                    to="/community"
                    role="menuitem"
                    className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    onClick={() => {
                      setMobileMenu(false);
                      setToggleActive(false);
                      setDashboardToggle(false);
                    }}
                  >
                    Community
                  </Link>
                  <Link
                    to="/gallery"
                    role="menuitem"
                    className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
                    onClick={() => {
                      setMobileMenu(false);
                      setToggleActive(false);
                      setDashboardToggle(false);
                    }}
                  >
                    Gallery
                  </Link>
                </div>

                {auth && auth.currentUser ? (
                  <div>
                    <Link
                      to="/account"
                      role="menuitem"
                      className="block w-full px-5 py-3 text-center font-medium text-blue-600 bg-gray-50 hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:bg-gray-100 focus:text-blue-700 transition duration-150 ease-in-out"
                      onClick={() => {
                        setMobileMenu(false);
                        setToggleActive(false);
                        setDashboardToggle(false);
                      }}
                    >
                      Account
                    </Link>
                  </div>
                ) : (
                  <>
                    <div>
                      <Link
                        to="/login"
                        role="menuitem"
                        className="block w-full px-5 py-3 text-center font-medium text-blue-600 bg-gray-50 hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:bg-gray-100 focus:text-blue-700 transition duration-150 ease-in-out"
                        onClick={() => {
                          setMobileMenu(false);
                          setToggleActive(false);
                          setDashboardToggle(false);
                        }}
                      >
                        Login
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="/signup"
                        role="menuitem"
                        className="block w-full px-5 py-3 text-center font-medium text-blue-600 bg-gray-50 hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:bg-gray-100 focus:text-blue-700 transition duration-150 ease-in-out"
                        onClick={() => {
                          setMobileMenu(false);
                          setToggleActive(false);
                          setDashboardToggle(false);
                        }}
                      >
                        Sign up
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Header;
