import React from "react";
import Helmet from "react-helmet";

import withAuthentication from "../components/Session/withAuthentication";
import { auth } from "api/firebase";
import { setPresence, fetchUserDataByUid } from "api/db";

import Header from "../components/Header";
import Footer from "../components/Footer";

import "../css/index.css";
import "../css/highlight.css";

import { hotjar } from "react-hotjar";

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto','Helvetica Neue', 'Arial', sans-serif;
}
*::selection {
  background-color: #e8e8e8;
`;

const Enlight = ({ children, pageContext, location }) => {
  if (typeof window !== "undefined") {
    hotjar.initialize(1884495, 6);

    auth.onAuthStateChanged(authUser => {
      if (authUser) {
        setPresence(authUser.uid);

        // saving user data to sessionStorage
        let userData = JSON.parse(sessionStorage.getItem("userData"));

        if (!userData || userData == undefined || userData == null) {
          fetchUserDataByUid(authUser.uid).on("value", function(snapshot) {
            let data = snapshot.val();
            let uid = snapshot.key;
            data.uid = uid;
            userData = data;
            sessionStorage.setItem("userData", JSON.stringify(data));
          });
        }

        // if (process.env.NODE_ENV === "production") {
        //   window.gtag("set", { user_id: authUser.uid });
        // }
      }
    });
  }

  return (
    <>
      <Helmet
        title="Enlight | Learn to code by building projects."
        meta={[
          {
            name: "description",
            content:
              "Enlight is a resource aimed to teach anyone to code through building projects. Hosting a wide variety of tutorials and demos, Enlight provides developers with sample projects and explains how they work."
          },
          {
            name: "og:title",
            content: "Enlight | Learn to code by building projects."
          },
          {
            name: "og:description",
            content: "Learn to code by building projects."
          },
          { name: "og:url", content: "https://enlight.nyc/" },
          { name: "og:image", content: "https://enlight.nyc/enlight.png" },
          { name: "twitter:card", content: "summary" },
          { name: "twitter:site", content: "@tryenlight" },
          {
            name: "twitter:url",
            content: "https://enlight.nyc/"
          },
          {
            name: "twitter:title",
            content: "Enlight | Learn to code by building projects."
          },
          {
            name: "twitter:description",
            content: "Learn to code by building projects."
          },
          {
            name: "twitter:image",
            content: "https://enlight.nyc/enlight.png"
          }
        ]}
      >
        <link
          rel="canonical"
          href="https://enlight.nyc"
          data-baseprotocol="https:"
          data-basehost="enlight.nyc"
        />
      </Helmet>
      <div>
        <GlobalStyle />
        {pageContext.layout === "onboarding" ? null : <Header location={location} />}
        {children}
        {pageContext.layout === "onboarding" ? null : <Footer />}
      </div>
    </>
  );
};

export default withAuthentication(Enlight);
